import {Routes} from '@angular/router';
import {AuthGuard} from '../../auth/services/auth.guard';
import {UserRole} from '../../auth/enums/user.role';

// Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: 'maps',
    loadChildren: () =>
      import('../../maps/maps.module').then((m) => m.MapsModule),
  },
  {
    path: 'dictionaries',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../../dictionaries/dictionaries.module').then(
        (m) => m.DictionariesModule
      ),
  },
  {
    path: 'estateManager/:id',
    loadChildren: () =>
      import('../../estate-manager/estate-manager.module').then(
        (m) => m.EstateManagerModule
      ),
  },
  {
    path: 'estate-export',
    loadChildren: () =>
      import('../../estate-manager/estate-manager.module').then(
        (m) => m.EstateManagerModule
      ),
  },
  {
    path: 'users-manager',
    canActivate: [AuthGuard],
    data: {
      title: 'Users',
      roles: [UserRole.ADMIN]
    },
    loadChildren: () =>
        import('../../users/users.module').then(
            (m) => m.UsersModule
        ),
  },
];
